@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap");
@import url("bootstrap.min.css");
@import url("all.min.css");
@import url("daterangepicker.css");
@import url("currency-flags.min.css");
@import url("bootstrap-select.min.css");
@import url("../scss/style.css");


* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    color: #6c6c6f;
    background-color: #fff;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    padding: 0 !important;
}

a {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

a:hover, a:focus {
    text-decoration: none;
    outline: 0px;
}

a:focus, button:focus, input:focus, select:focus {
    outline: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
}

h2 {
    color: #262934;
    font-size: 36px;
}

img {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

figure {
    margin: 0;
}

section {
    display: block;
    position: relative;
    padding: 100px 0;
    width: 100%;
}

/**
 *
 * Common CSS
 *
 */
@media screen and (max-width: 767px) {
    section {
        padding-bottom: 50px;
    }
}

@media screen and (min-width: 768px) {
    .nopadd-ltf {
        padding-left: 0;
    }

    .nopadd-rth {
        padding-right: 0;
    }

    .nopadd {
        padding-left: 0;
        padding-right: 0;
    }
}

.nopadd-btm {
    padding-bottom: 0 !important;
}

.btn {
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 0.8;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
}
label.btn{
  color: #6c757d;
}
@media screen and (min-width: 992px) {
    .btn:first-of-type {
        margin-right: 25px;
    }
}

.btn-filled {
    background-color: #256be6;
}

.btn-filled:hover {
    background-color: #154ABD;
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
}

.btn-filled:hover i {
    color: #000;
    position: relative;
    z-index: 9;
}

.btn-default {
    background-color: #256be6;
    border-radius: 50px;
    color: #fff;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.btn-default:hover {
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
}

.btn-round, .iconBox .btn {
    border-radius: 50px;
}

.btn-outline {
    border: 2px solid #fff;
}

.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.icon-left i {
    line-height: .8;
    margin-right: 20px;
}

.btn span {
    position: relative;
    z-index: 2;
}

.btn span.bh {
    background: #fff;
    border: 10px solid #ffffff8f;
    border-radius: 50%;
    display: block;
    height: 0;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: width 0.7s ease 0s, height 0.7s ease 0s;
    -o-transition: width 0.7s ease 0s, height 0.7s ease 0s;
    transition: width 0.7s ease 0s, height 0.7s ease 0s;
    width: 0;
    z-index: 1;
    opacity: 0;
}

.btn:hover span.bh {
    height: 500px;
    width: 500px;
    opacity: 1;
}

.circle-cursor {
    pointer-events: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    background: #000;
    font-size: 20px;
    text-align: center;
    -webkit-transition: linear .2s;
    -o-transition: linear .2s;
    transition: linear .2s;
    z-index: 9999999;
    top: 0;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: .5;
}

.tagline {
    color: #256be6;
    display: block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
}

@media screen and (max-width: 991px) {
    .tagline {
        font-size: 12px;
    }
}

.tagline.bar {
    padding-left: 70px;
}

.tagline.bar:after {
    background-color: #256be6;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 2px;
    width: 50px;
}

@media screen and (min-width: 992px) {
    .mb100 {
        margin-bottom: 100px;
    }
}

.bg-offwhite {
    background-color: #f7faff;
}

.sec-heading {
    text-align: center;
    margin-bottom: 76px;
}

.sec-heading .sec-title {
    color: #2f2f35;
    font-size: 36px;
    line-height: 48px;
    text-transform: capitalize;
    margin-bottom: 0;
}

@media screen and (max-width: 767px) {
    .sec-heading .sec-title {
        font-size: 28px;
        line-height: 36px;
    }
}

.sec-heading .sec-subtitle {
    color: #6c6c6f;
    font-size: 16px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 0;
}

.avatar {
    border-radius: 50%;
}

.roysha-icon {
    display: inherit;
    font-size: 70px;
    margin-bottom: 20px;
}

.roysha-icon span:before {
    color: #256be6 !important;
}

/**
 *  Preloader
 */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5f5f5;
    z-index: 99999999;
    width: 100%;
    height: auto;
}

#status {
    width: 250px;
    height: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url(../images/preloader.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/**
 * Header style
 */
 .header{
    top: 48px !important;
 }
.header, .header02, .header03, .header04 {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 88px;
    width: 100%;
    z-index: 9;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-color: rgba(0, 0, 0, 0.1);
}

.header.fixed-header, .fixed-header.header02, .fixed-header.header03, .fixed-header.header04 {
    position: fixed;
    background: #154ABD;
}

.header .container, .header02 .container, .header03 .container, .header04 .container {
    position: relative;
    height: 100%;
}

.header .logo, .header02 .logo, .header03 .logo, .header04 .logo {
    display: block;
    max-width: 138px;
}

.header .primary-menu, .header02 .primary-menu, .header03 .primary-menu, .header04 .primary-menu {
    margin-left: auto;
}

@media screen and (max-width: 991px) {
    .header .primary-menu ul, .header02 .primary-menu ul, .header03 .primary-menu ul, .header04 .primary-menu ul {
        background-color: #256be6;
        display: none;
        text-align: left;
        width: 100%;
    }

    .header .primary-menu ul:not(.sub-menu), .header02 .primary-menu ul:not(.sub-menu), .header03 .primary-menu ul:not(.sub-menu), .header04 .primary-menu ul:not(.sub-menu) {
        max-height: 400px;
        overflow-y: scroll;
        padding: 10px 0;
        position: absolute;
        top: 100%;
        left: 0;
    }
}

.header .primary-menu ul li, .header02 .primary-menu ul li, .header03 .primary-menu ul li, .header04 .primary-menu ul li {
    display: inline-block;
    position: relative;
}

@media screen and (max-width: 991px) {
    .header .primary-menu ul li.login, .header02 .primary-menu ul li.login, .header03 .primary-menu ul li.login, .header04 .primary-menu ul li.login {
        border: none;
    }
}

@media screen and (min-width: 992px) {
    .header .primary-menu ul li.login a, .header02 .primary-menu ul li.login a, .header03 .primary-menu ul li.login a, .header04 .primary-menu ul li.login a {
        padding: 5px 30px;
    }
}

.header .primary-menu ul li.has-menu-child i, .header02 .primary-menu ul li.has-menu-child i, .header03 .primary-menu ul li.has-menu-child i, .header04 .primary-menu ul li.has-menu-child i {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    right: 10px;
    top: 5px;
    width: 20px;
    height: 20px;
}

@media screen and (min-width: 992px) {
    .header .primary-menu ul li.has-menu-child i, .header02 .primary-menu ul li.has-menu-child i, .header03 .primary-menu ul li.has-menu-child i, .header04 .primary-menu ul li.has-menu-child i {
        right: -5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.header .primary-menu ul li.has-menu-child ul.sub-menu, .header02 .primary-menu ul li.has-menu-child ul.sub-menu, .header03 .primary-menu ul li.has-menu-child ul.sub-menu, .header04 .primary-menu ul li.has-menu-child ul.sub-menu {
    padding-left: 15px;
    border: 1px solid #ddd;
}

@media screen and (min-width: 992px) {
    .header .primary-menu ul li.has-menu-child ul.sub-menu, .header02 .primary-menu ul li.has-menu-child ul.sub-menu, .header03 .primary-menu ul li.has-menu-child ul.sub-menu, .header04 .primary-menu ul li.has-menu-child ul.sub-menu {
        background-color: #fff;
        text-align: left;
        -webkit-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        padding: 0;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: 100%;
        left: 0;
        width: 220px;
    }
}

.header .primary-menu ul li.has-menu-child ul.sub-menu li, .header02 .primary-menu ul li.has-menu-child ul.sub-menu li, .header03 .primary-menu ul li.has-menu-child ul.sub-menu li, .header04 .primary-menu ul li.has-menu-child ul.sub-menu li {
    display: block;
}

@media screen and (min-width: 992px) {
    .header .primary-menu ul li.has-menu-child ul.sub-menu a, .header02 .primary-menu ul li.has-menu-child ul.sub-menu a, .header03 .primary-menu ul li.has-menu-child ul.sub-menu a, .header04 .primary-menu ul li.has-menu-child ul.sub-menu a {
        color: #666;
        font-size: 14px;
        padding: 8px 5px 10px 25px;
        position: relative;
    }
}

.header .primary-menu ul li.has-menu-child ul.sub-menu a:before, .header02 .primary-menu ul li.has-menu-child ul.sub-menu a:before, .header03 .primary-menu ul li.has-menu-child ul.sub-menu a:before, .header04 .primary-menu ul li.has-menu-child ul.sub-menu a:before {
    content: '';
    width: 7px;
    height: 1px;
    border-radius: 50%;
    background: #154ABD;
    display: block;
    position: absolute;
    left: 10px;
    top: 20px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.header .primary-menu ul li.has-menu-child ul.sub-menu a:hover, .header02 .primary-menu ul li.has-menu-child ul.sub-menu a:hover, .header03 .primary-menu ul li.has-menu-child ul.sub-menu a:hover, .header04 .primary-menu ul li.has-menu-child ul.sub-menu a:hover {
    background-color: #154ABD;
    color: #fff;
}

.header .primary-menu ul li.has-menu-child ul.sub-menu a:hover:before, .header02 .primary-menu ul li.has-menu-child ul.sub-menu a:hover:before, .header03 .primary-menu ul li.has-menu-child ul.sub-menu a:hover:before, .header04 .primary-menu ul li.has-menu-child ul.sub-menu a:hover:before {
    background: #fff;
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
}

.header .primary-menu ul li a, .header02 .primary-menu ul li a, .header03 .primary-menu ul li a, .header04 .primary-menu ul li a {
    color: #fff;
    display: block;
    text-transform: capitalize;
    padding: 5px 25px;
    position: relative;
}

@media screen and (min-width: 992px) {
    .header .primary-menu ul li a, .header02 .primary-menu ul li a, .header03 .primary-menu ul li a, .header04 .primary-menu ul li a {
        padding: 30px 15px;
    }
}

@media screen and (max-width: 1199px) {
    .header .primary-menu ul li a, .header02 .primary-menu ul li a, .header03 .primary-menu ul li a, .header04 .primary-menu ul li a {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .header .primary-menu ul li a:hover, .header02 .primary-menu ul li a:hover, .header03 .primary-menu ul li a:hover, .header04 .primary-menu ul li a:hover {
        background-color: #1053c8;
    }
}

.header .primary-menu ul li a.login.btn.btn-outline.btn-round, .header02 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header03 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header04 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header .primary-menu ul li a.login.btn.btn-outline, .header02 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header02 .primary-menu ul li a.login.btn.btn-outline, .header03 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header03 .primary-menu ul li a.login.btn.btn-outline, .header04 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header04 .primary-menu ul li a.login.btn.btn-outline {
    padding: 11px 25px !important;
}

@media screen and (min-width: 992px) {
    .header .primary-menu ul li a.login.btn.btn-outline.btn-round, .header02 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header03 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header04 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header .primary-menu ul li a.login.btn.btn-outline, .header02 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header02 .primary-menu ul li a.login.btn.btn-outline, .header03 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header03 .primary-menu ul li a.login.btn.btn-outline, .header04 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header04 .primary-menu ul li a.login.btn.btn-outline {
        min-width: auto;
        top: 13px;
        position: relative;
    }

    .header .primary-menu ul li a.login.btn.btn-outline.btn-round:hover span, .header02 .primary-menu ul li a.login.btn.btn-outline.btn-round:hover span, .header03 .primary-menu ul li a.login.btn.btn-outline.btn-round:hover span, .header04 .primary-menu ul li a.login.btn.btn-outline.btn-round:hover span, .header .primary-menu ul li .iconBox a.login.btn.btn-outline:hover span, .iconBox .header .primary-menu ul li a.login.btn.btn-outline:hover span, .header02 .primary-menu ul li .iconBox a.login.btn.btn-outline:hover span, .iconBox .header02 .primary-menu ul li a.login.btn.btn-outline:hover span, .header03 .primary-menu ul li .iconBox a.login.btn.btn-outline:hover span, .iconBox .header03 .primary-menu ul li a.login.btn.btn-outline:hover span, .header04 .primary-menu ul li .iconBox a.login.btn.btn-outline:hover span, .iconBox .header04 .primary-menu ul li a.login.btn.btn-outline:hover span {
        color: #154ABD;
    }
}

@media screen and (max-width: 991px) {
    .header .primary-menu ul li a.login.btn.btn-outline.btn-round, .header02 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header03 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header04 .primary-menu ul li a.login.btn.btn-outline.btn-round, .header .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header .primary-menu ul li a.login.btn.btn-outline, .header02 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header02 .primary-menu ul li a.login.btn.btn-outline, .header03 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header03 .primary-menu ul li a.login.btn.btn-outline, .header04 .primary-menu ul li .iconBox a.login.btn.btn-outline, .iconBox .header04 .primary-menu ul li a.login.btn.btn-outline {
        border: none;
        text-align-last: left;
        font-weight: 400;
    }

    .header .primary-menu ul li a.login.btn.btn-outline.btn-round .bh, .header02 .primary-menu ul li a.login.btn.btn-outline.btn-round .bh, .header03 .primary-menu ul li a.login.btn.btn-outline.btn-round .bh, .header04 .primary-menu ul li a.login.btn.btn-outline.btn-round .bh, .header .primary-menu ul li .iconBox a.login.btn.btn-outline .bh, .iconBox .header .primary-menu ul li a.login.btn.btn-outline .bh, .header02 .primary-menu ul li .iconBox a.login.btn.btn-outline .bh, .iconBox .header02 .primary-menu ul li a.login.btn.btn-outline .bh, .header03 .primary-menu ul li .iconBox a.login.btn.btn-outline .bh, .iconBox .header03 .primary-menu ul li a.login.btn.btn-outline .bh, .header04 .primary-menu ul li .iconBox a.login.btn.btn-outline .bh, .iconBox .header04 .primary-menu ul li a.login.btn.btn-outline .bh {
        display: none;
    }
}

.header .primary-menu ul li:hover ul.sub-menu, .header02 .primary-menu ul li:hover ul.sub-menu, .header03 .primary-menu ul li:hover ul.sub-menu, .header04 .primary-menu ul li:hover ul.sub-menu {
    opacity: 1;
    visibility: visible;
}

@media screen and (max-width: 991px) {
    .header .primary-menu ul li, .header02 .primary-menu ul li, .header03 .primary-menu ul li, .header04 .primary-menu ul li {
        display: block;
    }
}

.header .primary-menu #mobile-menu-toggler, .header02 .primary-menu #mobile-menu-toggler, .header03 .primary-menu #mobile-menu-toggler, .header04 .primary-menu #mobile-menu-toggler {
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    line-height: .8;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media screen and (min-width: 992px) {
    .header .primary-menu #mobile-menu-toggler, .header02 .primary-menu #mobile-menu-toggler, .header03 .primary-menu #mobile-menu-toggler, .header04 .primary-menu #mobile-menu-toggler {
        display: none;
    }
}

.header02, .header03 {
    background-color: #154ABD;
    position: relative;
    height: auto;
}

.header02 .logo img, .header03 .logo img {
    /* -webkit-filter: brightness(0) invert(0);
    filter: brightness(0) invert(0); */
}

.header02 .primary-menu, .header03 .primary-menu {
    margin-left: 0;
}

.header02 .primary-menu #mobile-menu-toggler, .header03 .primary-menu #mobile-menu-toggler {
    color: #000;
}

.header02 .primary-menu ul li:last-child, .header03 .primary-menu ul li:last-child {
    margin-left: auto;
}

@media screen and (min-width: 992px) {
    .header02 .primary-menu ul li a, .header03 .primary-menu ul li a {
        color: #000;
    }
}

@media screen and (min-width: 992px) {
    .header02 .primary-menu .btn, .header03 .primary-menu .btn {
        background-color: #004bc8;
    }
}

.header03 .social-icons {
    display: inline-block;
}

.header03 .social-icons a:not(:last-child) {
    border-right: 1px solid #fff;
}

.header03 .login {
    padding: 5px 20px;
    border-radius: 0;
    text-transform: capitalize;
}

.header03 .login:hover {
    color: #fff;
}

@media screen and (max-width: 767px) {
    .header03 .login {
        padding: 5px 13px;
    }
}

.header04 .login {
    min-width: auto;
    padding: 7px 25px;
    border-radius: 5px;
    text-transform: capitalize;
    letter-spacing: 1.8px;
}

.header04 .login:hover {
    color: #2f2f35;
    background-color: #fff;
}

@media screen and (max-width: 991px) {
    .header04 .login {
        margin-right: 35px;
    }
}

@media screen and (max-width: 991px) {
    .header-main {
        height: 80px;
    }
}

.header-top {
    padding: 10px 0;
    background: #154ABD;
}

.header-top .top-text {
    display: inline-block;
    color: #fff;
    font-size: 12px;
}

.header-top .top-text:nth-child(2) {
    margin-left: 20px;
}

.header-top .top-text i {
    margin-right: 5px;
}

.header-top .social-icons a {
    display: inline-block;
    color: #fff;
    font-size: 13px;
    line-height: 1;
    padding: 0 7px;
}

@media screen and (max-width: 575px) {
    .header-top {
        display: none;
    }
}
/* ===================header-top1 =====================*/
.header-top1 {
    padding: 10px 0;
    background: #000;
}

.header-top1 .top-text {
    display: inline-block;
    color: #fff;
    font-size: 12px;
}

.header-top1 .top-text:nth-child(2) {
    margin-left: 20px;
}

.header-top1 .top-text i {
    margin-right: 5px;
}

.header-top1 .social-icons a {
    display: inline-block;
    color: #fff;
    font-size: 13px;
    line-height: 1;
    padding: 0 7px;
}

@media screen and (max-width: 575px) {
    .header-top1 {
        display: none;
    }
}
/* =================================================== */

.primary-menu .btn span {
    color: #fff;
}

.primary-menu .btn:first-of-type {
    margin-right: 0;
}

.social-icons, .header-language{
    /* float: left; */
    text-align: right;
    display: inline-block;
}
.header-language{
    margin-left: 10px;
}
.langbtn{
    color: #fff;
    font-size: 13px;
}
/**
 * Footer
 */
.footer {
    background-size: auto;
    background-position: center top;
    background-repeat: no-repeat;
    /*background-image: url("../images/footer-bg.png");*/
    background-color: #154ABD;
    position: relative;
}

.footer .foo-top {
    padding: 100px 0 90px;
}

.footer .widget h5 {
    color: #fff;
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.footer .widget h5:before {
    content: '';
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    height: 2px;
    width: 30px;
}

@media screen and (max-width: 575px) {
    .footer .widget h5 i {
        font-size: 14px;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 575px) {
    .footer .widget {
        margin-top: 30px;
    }
}

@media screen and (max-width: 991px) {
    .footer .foo-nav {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 575px) {
    .footer .foo-nav {
        margin-bottom: 0;
    }

    .footer .foo-nav ul {
        display: none;
    }

    .footer .foo-nav h5 {
        cursor: pointer;
        margin-bottom: 0;
    }
}

.footer .foo-nav li a {
    color: #fff;
    display: block;
    font-size: 15px;
    padding: 5px 0;
    padding-left: 10px;
    position: relative;
}

.footer .foo-nav li a:before {
    content: '\f0da';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 5px;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.footer .foo-nav li a:hover {
    padding-left: 15px;
}

.footer .foo-address address {
    color: #fff;
    margin-bottom: 35px;
}

.footer .foo-address address a {
    color: #fff;
}

.footer .foo-btm {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 25px;
}

.footer .foo-navigation ul li {
    display: inline-block;
}

.footer .foo-navigation ul li a {
    color: #fff;
    display: block;
    font-size: 15px;
    padding-right: 20px;
}

@media screen and (max-width: 991px) {
    .footer .foo-navigation ul li a {
        font-size: 12px;
        padding-right: 12px;
    }
}

.footer .foo-navigation ul li:last-child a {
    padding-right: 0;
}

@media screen and (max-width: 767px) {
    .footer .foo-navigation {
        text-align: center;
    }
}

.footer .copyright {
    color: #fff;
    font-size: 15px;
    text-align: center;
}

.footer .copyright a {
    color: #fff;
    display: inline-block;
    font-weight: 500;
}

@media screen and (min-width: 768px) {
    .footer .copyright {
        text-align: right;
    }
}

@media screen and (max-width: 991px) {
    .footer .copyright {
        font-size: 12px;
    }
}


@media screen and (max-width: 991px) {
    ul.sub-menu {
        position: relative;
        border: none !important;
        /*display: block !important;*/
    }

    .header02 .primary-menu ul li.has-menu-child ul.sub-menu {
        padding-left: 0;
        overflow: hidden;
    }

    .dropdown-header h5 {
        color: #fff !important;
    }
    .primary-menu > ul{
        max-height: inherit !important;
        height: auto !important;
        overflow: inherit !important;
    }
    li.has-menu-child:hover ul.sub-menu {
        display: none;
    }
    .pro-menu-drop > a{
        padding-left: 25px !important;
    }
    .header-pro-thumb{
        display: none;
    }
    .profile-name{
        position: relative;
    }
}
/* ================================btn-sp=============================== */
.btn-sp {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding: 0px 30px;
    cursor: pointer;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    height: 40px!important;
    border-radius: 1.25rem;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-sp:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-sp:focus {
    outline: none;
}

.btn-sp.color-primary {
    background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.btn-sp.color-dark {
    background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.btn-sp.color-white {
    background-image: linear-gradient(to right, #ffffff, #e6e9eb, #cdd3d7, #b4bec4);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
    color:#256BE6!important;
}
