/* You can add global styles to this file, and also import other style files */
@import './assets/admin/css/style.css';
@import './assets/css/all.min.css';
@import './assets/css/pe-icon-7-stroke.css';
@import './assets/css/roysha-icons.css';
@import './assets/css/bootstrap.min.css';
@import './assets/css/owl.carousel.min.css';
@import './assets/css/jquery.fancybox.min.css';
@import './assets/css/nice-select.css';
@import './assets/css/style.css';
@import "./assets/plugin/build/css/intlTelInput.css";